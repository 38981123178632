/* titillium-web-300 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: url('./300/titillium-web-v6-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Light'), local('TitilliumWeb-Light'),
    url('./300/titillium-web-v6-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./300/titillium-web-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./300/titillium-web-v6-latin-300.woff') format('woff'), /* Modern Browsers */
    url('./300/titillium-web-v6-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./300/titillium-web-v6-latin-300.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-regular - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url('./400/titillium-web-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
    url('./400/titillium-web-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./400/titillium-web-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./400/titillium-web-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./400/titillium-web-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./400/titillium-web-v6-latin-regular.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-600 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url('./600/titillium-web-v6-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'),
    url('./600/titillium-web-v6-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./600/titillium-web-v6-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./600/titillium-web-v6-latin-600.woff') format('woff'), /* Modern Browsers */
    url('./600/titillium-web-v6-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./600/titillium-web-v6-latin-600.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
/* titillium-web-700 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url('./700/titillium-web-v6-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'),
    url('./700/titillium-web-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./700/titillium-web-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./700/titillium-web-v6-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./700/titillium-web-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./700/titillium-web-v6-latin-700.svg#TitilliumWeb') format('svg'); /* Legacy iOS */
}
