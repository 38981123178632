/* You can add global styles to this file, and also import other style files */
@import "src/assets/fonts/titillium/titillium.scss";

html, body {
  height: 100%;
}

.body-bg {
  background-color: var(--bs-body-bg);
}

body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
}

label.required::after {
  content: '*';
  color: red;
}

.clickable {
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12);
  }
}

.m-top-100-px {
  margin-top: 100px;
}

.ng-invalid:not(form):not(.ng-untouched):not(:focus) {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.2);
}

.p-2, .padding-2 {
  padding: 16px !important;
}

.p-top-2 { padding-top: 16px; }

.p-bottom-1 { padding-bottom: 8px; }

.p-x-0 { padding-left: 0; padding-right: 0;}

.p-x-1 {
  padding-left: 8px;
  padding-right: 8px;
}
.p-x-2 {
  padding-left: 16px;
  padding-right: 16px;
}

.p-x-4 {
  padding-left: 32px;
  padding-right: 32px;
}

.p-y-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.m-top-2 { margin-top: 16px; }
.m-right-2 { margin-right: 16px; }
.m-x-1 {
  margin-right: 8px;
  margin-left: 8px;
}
.m-y-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.text-2 { font-size: 16px; }
.text-3 { font-size: 24px; }
.text-4 { font-size: 32px; }
.text-5 { font-size: 40px; }
.text-8 { font-size: 64px; }
.text-10 { font-size: 80px; }

.fw-600 { font-weight: 600; }
.fw-700 { font-weight: 700; }

.lineh-2 { line-height: 16px; }
.lineh-3 { line-height: 24px; }
.lineh-4 { line-height: 32px; }
.lineh-4-5 { line-height: 36px; }
.lineh-7-5 { line-height: 60px; }

.color-blue { color: #0096D8; }

.logo-responsive{
  max-width: 100%;
  max-height: 100%;
}

.card-shadow {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.no-underline {
  text-decoration: none;
}

.overflow-y-scroll { overflow-y: scroll; }

// these offcanvas classes are added as the offcanvas does not work correctly in the bootstrap library
.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translate(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translate(100%);
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.visibility-hidden {
  visibility: hidden;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.scrollable {
  max-height: 75vh;
  overflow-y: auto !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
  &.translate-y-50-perc {
    transform: rotate(180deg) translateY(50%);
  }
}

.rotate-270 {
  transform: rotate(270deg);
}

.mode-vertical-lr {
  writing-mode: vertical-lr;
}

.transition-1s {
  transition: 1s;
}

.min-h-100-perc {
  min-height: 100%;
}

.h-100-perc {
  height: 100%;
}

.w-100-perc {
  width: 100%;
}

.shadow {
  box-shadow: 0 0 2rem 0 rgba(41,48,66,.1);
}

.max-w-50 {
  max-width: 400px;
}

.max-w-75 {
  max-width: 600px !important;
}

.z-index-1 {
  z-index: 1;
}
